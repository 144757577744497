




















































































































import { Vue, Component, Watch, Prop }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'
import { formatDateSinTime, formatDayHourZDateFromMoment, formatStringServeurToDate, formatToHourMinutesFromAny, getWeekDayWithDayMonth } from '@/utils/helpers'
import { EtatCreneau, EtatDispo } from '@/types/Disponibilites'
import { Ability } from '@/types/Ability'
import Semainier from '@/components/GestionExaminateurs/Disponibilites/Semainier.vue'
import { BModal } from 'bootstrap-vue'
import _ from 'lodash'

@Component({
    computed: {
        ...mapState('disponibilite', ['compteurs_series_journees_TP', 'journees_inactive', 'pause_count', 'lock_creneau', 'loading_interface', 'creneaux_TP']),
        ...mapGetters('auth', ['can']),
        ...mapGetters('examinateur', ['examinateur_select', 'get_examinateurs_equipe']),
        ...mapState('examinateur', ['examinateurs_equipes']),
        ...mapState('session', ['sessionActive']),
        ...mapState('serie', ['series']),
        ...mapState('sessionuser', ['error_session_user']),
        ...mapState('sessionuser', ['sessionUser']),
        ...mapGetters('disponibilite', ['genere_plages', 'get_creneaux_TP'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Semainier,
        'b-modal': BModal

    }
})

export default class SemainierTP extends Vue {
    @Prop() selected_serie_id?: any
    @Prop() ensemble_id?: any
    @Prop() epreuve_correction?: any
    @Prop() refresh_creneaux?: boolean
    @Prop() readOnlyGroup?: boolean
    @Prop() h_perso?: any

    style_height =  'height: 40px;'

    Ability = Ability
    creneaux: any = []
    selected_priority_id = EtatDispo.ETAT_PRIORITAIRE
    formatDateSinTime = formatDateSinTime
    getWeekDayWithDayMonth = getWeekDayWithDayMonth
    formatToHourMinutesFromAny = formatToHourMinutesFromAny
    EtatCreneau = EtatCreneau
    EtatDispo = EtatDispo
    jours_series_full: Array<any> = []
    jours_series_show: Array<any> = []
    jours_series: Array<any> = []
    lockCreneau = false
    plages: Array<any> = []
    todayDate = Date.now()
    compteurs_tp: Array<any> = []
    examinateurs: any = []
    readOnly = false
    commentaire = ''
    compteur: any = {}
    loading_semainier = false

    /**
     * @description Récupère le pourcentage de la largeur du contenu
     * @returns {number} - Retourne le pourcentage de la largeur du contenu
     */
    getWidthContent(): number {
        const test: any = Object.keys(this.jours_series)[0]
        if (test && test !== undefined) {
            if (!this.plages[this.jours_series[test].jour] || this.plages[this.jours_series[test].jour].length <= 2) {
                return 100
            } else {
                return (100 * ((this.plages[this.jours_series[test].jour].length)) / 2)
            }
        }
        return 100
    }

    /**
     * @description Vérifie l'état du créneau
     * @returns {void}
     */
    @Watch('sessionUser')
    majDisabled(): void {
        this.isDisabled()
    }

    /**
     * @description Génère les plages du semainier
     * @returns {void}
     */
    @Watch('jours_series_show')
    generePlages(): void {
        if (this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP && this.examinateurs.length !== 0) {
            this.genererSemainier()
        }
    }

    /**
     * @description Met à jour le semainier
     * @returns {void}
     */
    @Watch('selected_serie_id')
    @Watch('examinateurs_equipes')
    @Watch('refresh_creneaux')
    reloadSemainier(): void {
        this.initDatasExaminateur()
    }

    /**
     * @description Met à jour le créneau sélectionné
     * @param {any} event - Evénement
     * @param {string} jour - Jour concerné
     * @param {any} creneau_select - Créneau sélectionné
     * @returns {void}
     */
    selectConcourChangeHandler(event: any,  jour: string, creneau_select: any): void {

        const creneau = {
            user_id: null,
            ensemble_id: this.ensemble_id,
            jour: jour,
            h_debut: creneau_select.h_debut,
            h_fin: creneau_select.h_fin,
            etat: this.selected_priority_id,
            actif: EtatCreneau.CRENEAU_ACTIF,
            nb_candidats_prio: creneau_select.nb_candidats_prio,
            nb_candidats_supp: creneau_select.nb_candidats_supp,
            concour_id: event.target.value
        }

        if (creneau_select.id === 0) {
            this.$store.dispatch('disponibilite/saveCreneau', { payload: { 'creneaux': [creneau] } })
                .then((response) => {
                    creneau_select.id = response.data.data.id
                    this.genererSemainier()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        } else {
            this.$store.dispatch('disponibilite/updateCreneau', { creneau_id: creneau_select.id, payload: creneau })
                .then(() => {
                    this.genererSemainier()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * @description Met à jour les compteurs
     * @returns {void}
     */
    updateCompteur(): void {
        this.compteur = {
            total: 0,
            valide: true,
            sceances_serie_min: this.epreuve_correction.sceances_serie_min,
            sceances_par_series: [],
            totalParConcours: [],
            totalParUsers: [],
            totalParCreneaux: []
        }

        const creneauxConcours = this.$store.state.disponibilite.creneaux_TP.filter((ctp: any) => ctp.concour_id !== null)
        for (let i = 0; i < creneauxConcours.length; i++) {
            if (this.compteur.totalParConcours[creneauxConcours[i].concour_id] === undefined) {
                this.compteur.totalParConcours[creneauxConcours[i].concour_id] = {
                    total: 0,
                    creneaux: [formatStringServeurToDate(creneauxConcours[i].h_debut) + '_' + formatStringServeurToDate(creneauxConcours[i].h_fin)]
                }
            } else {
                this.compteur.totalParConcours[creneauxConcours[i].concour_id].creneaux.push(formatStringServeurToDate(creneauxConcours[i].h_debut) + '_' + formatStringServeurToDate(creneauxConcours[i].h_fin))
            }

            const jours_serie_temp = this.jours_series_full.find((jour: any) => jour.jour === creneauxConcours[i].jour)
            if (jours_serie_temp) {
                const serie_id_creneau = jours_serie_temp.serie_id
                if (this.compteur.sceances_par_series[serie_id_creneau]) {
                    this.compteur.sceances_par_series[serie_id_creneau] += creneauxConcours[i].nb_candidats_prio
                } else {
                    this.compteur.sceances_par_series[serie_id_creneau] = creneauxConcours[i].nb_candidats_prio
                }
            }
        }

        const creneauxUsers = this.$store.state.disponibilite.creneaux_TP.filter((ctp: any) => ctp.user_id !== null)
        for (let j = 0; j < creneauxUsers.length; j++) {
            const jours_serie_tempb = this.jours_series_full.find((jour: any) => jour.jour === creneauxUsers[j].jour)
            if (jours_serie_tempb) {
                const serie_id_of_creneau = jours_serie_tempb.serie_id
                this.compteur.total += 1
                if (this.compteur.totalParUsers[creneauxUsers[j].user_id] && this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau]) {
                    this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau].total += 1
                } else if (this.compteur.totalParUsers[creneauxUsers[j].user_id]) {
                    this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau] = {
                        total: 1,
                        concours: []
                    }
                } else {
                    this.compteur.totalParUsers[creneauxUsers[j].user_id] = {
                        series: []
                    }
                    this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau] = {
                        total: 1,
                        concours: []
                    }
                }

                const hd = formatStringServeurToDate(creneauxUsers[j].h_debut)
                const hf = formatStringServeurToDate(creneauxUsers[j].h_fin)
                if (this.compteur.totalParCreneaux[hd + '_' + hf]) {
                    this.compteur.totalParCreneaux[hd + '_' + hf] += 1
                } else {
                    this.compteur.totalParCreneaux[hd + '_' + hf] = 1
                }

                for (const idConc in this.compteur.totalParConcours) {
                    for (let k = 0; k < this.compteur.totalParConcours[idConc].creneaux.length; k++) {
                        if (this.compteur.totalParConcours[idConc].creneaux[k] === hd + '_' + hf) {
                            this.compteur.totalParConcours[idConc].total += 1
                            if (this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau].concours[idConc]) {
                                this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau].concours[idConc] += 1
                            } else {
                                this.compteur.totalParUsers[creneauxUsers[j].user_id].series[serie_id_of_creneau].concours[idConc] = 1
                            }
                        }
                    }
                }
            }
        }

        for (const serie_id in this.compteur.sceances_par_series) {
            if (this.compteur.sceances_par_series[serie_id] === undefined || this.compteur.sceances_par_series[serie_id] < this.compteur.sceances_serie_min) {
                this.compteur.valide = false
            }
        }

        this.$emit('updateCompteursTp', this.compteur)
    }

    /**
     * @description Met à jour les compteurs suite à la saisie des inputs
     * @param {any} creneau_select - Créneau sélectionné
     * @param {string} jour - Jour concerné
     * @param {any} value - Valeur saisie
     * @param {EtatDispo} prio - Priorité
     * @returns {void}
     */
    timeOut = 0
    changeInputCompteur(creneau_select: any, jour: string, value: any, prio: EtatDispo) {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
            this.changeInputCompteurSuite(creneau_select, jour, value, prio)
        }, 750)
    }

    changeInputCompteurSuite(creneau_select: any, jour: string, value: any, prio: EtatDispo): void {
        this.compteurs_tp[this.selected_serie_id].prio = 0
        this.compteurs_tp[this.selected_serie_id].non_prio = 0
        this.$store.state.disponibilite.creneaux_prio = 0
        this.$store.state.disponibilite.creneaux_non_prio = 0

        for (const j in this.compteurs_tp[this.selected_serie_id].journees) {
            const journee = this.compteurs_tp[this.selected_serie_id].journees[j]
            for (const c in journee) {
                // Compteurs totaux de la série
                this.compteurs_tp[this.selected_serie_id].prio += Number(journee[c].prio)
                this.compteurs_tp[this.selected_serie_id].non_prio += Number(journee[c].non_prio)
                // Compteurs totaux
                this.$store.state.disponibilite.creneaux_prio += Number(journee[c].prio)
                this.$store.state.disponibilite.creneaux_non_prio += Number(journee[c].non_prio)
            }
        }
        this.$emit('compteurs_tp', this.compteurs_tp)

        // Sauvegarde du créneau / compteurs
        if (prio === EtatDispo.ETAT_PRIORITAIRE) {
            creneau_select.nb_candidats_prio = Number(value)
        } else if (prio === EtatDispo.ETAT_NON_PRIORITAIRE) {
            creneau_select.nb_candidats_supp = Number(value)
        }
        this.addUpdateDestroyCreneau(null, jour, creneau_select)
    }

    /**
     * @description Génère le semainier
     * @returns {void}
     */
    genererSemainier(): void {
        this.$store.commit('disponibilite/SET_LOADING_INTERFACE', true)
        if (!this.creneaux) {
            this.creneaux = []
        }
        this.plages = this.$store.getters['disponibilite/genere_plages'](this.jours_series, this.epreuve_correction, this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW), this.$store.state.sessionuser.sessionUser, true)

        const testPlages: any = {}
        for(const p in this.plages) {
            for(const pl in this.plages[p]) {
                if(this.plages[p][pl].rdvs.length > 0)   {
                    if(!testPlages[p]){
                        testPlages[p] = []
                    }

                    testPlages[p].push(this.plages[p][pl])
                }
            }
        }
        this.plages = testPlages

        this.creneaux = this.$store.getters['disponibilite/get_creneaux_TP'](this.plages, this.examinateurs)
        this.loadCompteurs()
        this.$store.commit('disponibilite/SET_LOADING_INTERFACE', false)
        this.updateCompteur()
        this.loading_semainier = false
    }

    /**
     * @description Charge les compteurs avec inputs
     * @returns {void}
     */
    loadCompteurs(): void {
        this.compteurs_tp = this.$store.getters['disponibilite/get_compteurs_serie_journee_TP'](this.$store.state.serie.series)
        this.$emit('compteurs_tp', this.compteurs_tp)
    }

    /**
     * @description Détermine la classe CSS en fonction de l'état de la vignette
     * @param {any} rdv - Créneau concerné
     * @returns {string} - Retourne la classe CSS correspondante
     */
    getClassEtatVignette(rdv: any): string {
        if (rdv) {
            switch(rdv.actif) {
                case EtatCreneau.CRENEAU_ACTIF:
                    if (rdv.etat === EtatDispo.ETAT_PRIORITAIRE) {
                        return 'vignette_prioritaire'
                    }
                    break
                case EtatCreneau.CRENEAU_INACTIF:
                    // Seul le responsable les voient
                    if (rdv.etat === EtatDispo.ETAT_PRIORITAIRE) {
                        return this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE) ? 'vignette_inactive_prioritaire' : 'vignette_prioritaire'
                    }
                    break
                case EtatCreneau.CRENEAU_INDISPONIBLE:
                    return 'vignette_indisponible'
            }
        }
        return ''
    }

    /**
     * @description Vérifie si le créneau doit être désactivé
     * @returns {object} - Retourne un objet avec la propriété disabled
     */
    isDisabled(): object {
        // Check le readOnly pour les épreuves de TP
        if ((this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE) &&
            this.$store.state.sessionuser.sessionUser.disponibilites_validated_at &&
            this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) ||
            (!this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE) && (!this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN)))) {
            this.readOnly = true
        } else {
            if (this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN)) {
                // Vérifie si l'examinateur est chef de saisie TP
                if (this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0] && this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].user_id === this.$store.getters['examinateur/examinateur_select_infos'].id) {
                    // Vérifie si le chef de TP a soumis ces dispos
                    if (this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at) {
                        this.readOnly = true
                    } else {this.readOnly = !this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0] ||
                        (this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].user_id !== this.$store.getters['examinateur/examinateur_select_infos'].id);}
                } else {
                    this.readOnly = true
                }
            } else {
                this.readOnly = false
            }
        }

        // Check les disabled des creneaux et inputs
        if (!this.readOnly && !this.readOnlyGroup) {
            if (this.lockCreneau) {
                return { disabled: true }
            } else {
                return { disabled: false }
            }
        } else {
            return { disabled: true }
        }
    }

    /**
     * @description Ajoute, met à jour ou supprime un créneau
     * @param {any} examinateur - Examinateur concerné
     * @param {string} jour - Jour concerné
     * @param {any} creneau_select - Créneau sélectionné
     * @returns {void}
     */
    addUpdateDestroyCreneau(examinateur: any, jour: string, creneau_select: any): void {
        this.$store.state.disponibilite.error_dispos = null
        this.lockCreneau = true

        // CAS N°1 : Le créneau cliqué à un ID et un user_id -> On le supprime car il était sélectionné
        if (creneau_select.id !== 0 && creneau_select.user_id) {
            // Retrait du créneau
            this.$store.dispatch('disponibilite/deleteCreneau', { creneau_id: creneau_select.id })
                .then(() => {
                    this.genererSemainier()
                    this.lockCreneau = false
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        } else if (creneau_select.id !== 0 && creneau_select.user_id === null) { // CAS N°2 : Créneau compteur à modifier
            const creneau = {
                user_id: examinateur ? examinateur.id : null,
                ensemble_id: this.ensemble_id,
                jour: jour,
                h_debut: creneau_select.h_debut,
                h_fin: creneau_select.h_fin,
                etat: this.selected_priority_id,
                actif: EtatCreneau.CRENEAU_ACTIF,
                nb_candidats_prio: creneau_select.nb_candidats_prio,
                nb_candidats_supp: creneau_select.nb_candidats_supp,
                concour_id: examinateur ? null : creneau_select.concour_id
            }

            this.$store.dispatch('disponibilite/updateCreneau', { creneau_id: creneau_select.id, payload: creneau })
                .then(() => {
                    this.genererSemainier()
                    this.lockCreneau = false
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        } else if (creneau_select.id === 0) { // CAS N°3 : Le créneau cliqué à un ID à 0 -> Création du créneau pour l'examinateur de la ligne
            // Création du payload
            const creneau = {
                user_id: examinateur ? examinateur.id : null,
                ensemble_id: this.ensemble_id,
                jour: jour,
                h_debut: creneau_select.h_debut,
                h_fin: creneau_select.h_fin,
                etat: this.selected_priority_id,
                actif: EtatCreneau.CRENEAU_ACTIF,
                nb_candidats_prio: creneau_select.nb_candidats_prio,
                nb_candidats_supp: creneau_select.nb_candidats_supp,
                concour_id: examinateur ? null : creneau_select.concour_id
            }

            this.$store.dispatch('disponibilite/saveCreneau', { payload: { 'creneaux': [creneau] } })
                .then((response) => {
                    this.genererSemainier()
                    this.lockCreneau = false

                    // Maj de l'interface
                    this.$store.state.disponibilite.creneaux_examinateur.push(response.data.data)
                    if (examinateur && this.epreuve_correction.concours.length === 1) {
                        const creneauxConcours = this.$store.state.disponibilite.creneaux_TP.filter((ctp: any) => {
                            return ctp.h_debut === formatDayHourZDateFromMoment(creneau_select.h_debut) && ctp.h_fin === formatDayHourZDateFromMoment(creneau_select.h_fin) && ctp.concour_id !== null
                        })
                        if (creneauxConcours.length === 0) {
                            creneau_select.concour_id =  this.epreuve_correction.concours[0].id
                            this.addUpdateDestroyCreneau(null, jour, creneau_select)
                        }
                    }
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * @description Initialise les créneaux de l'équipe en fonction de la série sélectionnée
     * @returns {void}
     */
    initDatasExaminateur(): void {
        if (this.loading_semainier) {
            return
        }
        this.loading_semainier = true

        this.examinateurs = this.$store.getters['examinateur/get_examinateurs_equipe']
        // Load des creneaux de l'équipe
        this.$store.dispatch('disponibilite/getCreneauxEnsemble', {
            ensemble_id: this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].id
        })
            .then(() => {
                this.jours_series_full = _.orderBy(this.epreuve_correction.series, 'jour', 'asc')
                this.jours_series_show = this.jours_series_full.filter((js: any) => js.serie_id === parseInt(this.selected_serie_id))
                const serieTemp = this.$store.state.serie.series.find((s: any) => s.id === parseInt(this.selected_serie_id))
                if (serieTemp) {
                    this.jours_series = this.$store.getters['disponibilite/create_jours_serie'](serieTemp, this.jours_series_show, this.h_perso)
                }
            })
    }

    /**
     * @description Retourne si le créateur du créneau correspond à l'utilisateur connecté
     * @param {number} creator_id - ID du créateur du créneau
     * @returns {string} - Retourne une indication si le créneau a été créé par un autre utilisateur
     */
    is_creator(creator_id: number): string {
        if (creator_id !== undefined) {
            if (this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].user_id !== creator_id) {
                this.$emit('create_by_other', true)
                return '<span class="fw-bold indication_resp">*</span>'
            }
        }
        return ''
    }

    load () {
        if (this.selected_serie_id && this.ensemble_id && this.epreuve_correction) {
            this.initDatasExaminateur()
        }
    }

    /**
     * @description Montage du composant
     * @return {void}
     */
    mounted(): void {
        this.load()
    }

}














































































































import { Vue, Component, Watch, Prop }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'
import { formatDateSinTime, formatDayHourZDateFromMoment, formatStringServeurToDate, formatToHourMinutesFromAny, getWeekDayWithDayMonth } from '@/utils/helpers'
import { EtatCreneau, EtatDispo } from '@/types/Disponibilites'
import { Ability } from '@/types/Ability'
import { BModal } from 'bootstrap-vue'
import _ from 'lodash'

@Component({
    computed: {
        ...mapState('disponibilite', ['compteurs_series_journees', 'journees_inactive', 'pause_count', 'creneaux_examinateur', 'creneaux_prio', 'creneaux_non_prio', 'creneaux_actifs', 'lock_creneau', 'messages_regles_journees', 'loading_interface', 'count_ligne_heure']),
        ...mapGetters('auth', ['can']),
        ...mapGetters('examinateur', ['examinateur_select']),
        ...mapState('session', ['sessionActive']),
        ...mapState('serie', ['series']),
        ...mapState('sessionuser', ['error_session_user']),
        ...mapState('sessionuser', ['sessionUser']),
        ...mapGetters('disponibilite', ['get_priorite_next', 'get_priorite_total', 'get_compteurs_serie_journee', 'check_regles_creneaux', 'applique_regles_creneaux'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'b-modal': BModal
    }
})

export default class Semainier extends Vue {
    @Prop() ensemble_id?: any
    @Prop() selected_serie_id?: any
    @Prop() epreuve_correction?: any
    @Prop() h_perso?: any
    @Prop() selected_priority_id?: number
    @Prop() refresh_creneaux?: boolean
    @Prop() readOnlyGroup?: boolean
    @Prop() loading?: boolean

    Ability = Ability
    creneaux: any = []
    formatDateSinTime = formatDateSinTime
    getWeekDayWithDayMonth = getWeekDayWithDayMonth
    formatToHourMinutesFromAny = formatToHourMinutesFromAny
    EtatCreneau = EtatCreneau
    EtatDispo = EtatDispo
    jours_series_full: Array<any> = []
    jours_series_show: Array<any> = []
    jours_series: Array<any> = []
    plages: Array<any> = []
    rdvs: Array<any>= []
    lockCreneau = false
    todayDate = Date.now()
    readOnly = false
    applique_regle = true
    commentaire = ''
    loading_semainier = false

    /**
     * @description Lance la construction du semainier
     * @return {void}
     */
    @Watch('selected_serie_id', { deep: true })
    @Watch('ensemble_id', { deep: true })
    @Watch('epreuve_correction', { deep: true })
    setGabaritSemainier(): void {
        this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
        this.jours_series_full = _.orderBy(this.epreuve_correction.series, 'jour', 'asc')
        this.jours_series_show = this.jours_series_full.filter((js: any) => js.serie_id === parseInt(this.selected_serie_id))
        const selectedSerie = this.$store.state.serie.series.find((s: any) => s.id === parseInt(this.selected_serie_id))
        if (selectedSerie) {
            this.jours_series = this.$store.getters['disponibilite/create_jours_serie'](selectedSerie, this.jours_series_show, this.h_perso)
            this.genererSemainier()
        }
    }

    /**
     * @description Recharge le semainier
     * @return {void}
     */
    @Watch('refresh_creneaux', { immediate: true, deep: true })
    reloadSemainier(): void {
        if (this.$props.refresh_creneaux) {
            this.genererSemainier()
        }
    }

    /**
     * @description Génère le semainier
     * @return {void}
     */
    genererSemainier(): void {
        if (this.loading_semainier) {
            return
        }
        this.loading_semainier = true

        this.waitRequestAnimationFrame().then(() => {
            this.$store.commit('disponibilite/SET_LOADING_INTERFACE', true)
            this.applique_regle = this.$store.getters['disponibilite/applique_regles_creneaux'](this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW), this.$store.state.sessionuser.sessionUser)
            this.plages = this.$store.getters['disponibilite/genere_plages'](this.jours_series, this.epreuve_correction, this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW), this.$store.state.sessionuser.sessionUser)
            this.rdvs = this.$store.getters['disponibilite/genere_ligne_rdvs'](this.plages)
            this.creneaux = this.$store.getters['disponibilite/get_creneaux'](this.plages)

            // Applique les règles à respecter pour la sélection des creneaux
            this.$store.getters['disponibilite/check_regles_creneaux'](this.creneaux, this.epreuve_correction)
            this.$store.commit('disponibilite/SET_LOADING_INTERFACE', false)
            this.checkCreneauxDisabled()
            this.loading_semainier = false
        })
    }

    /**
     * @description Attends le rafraichissement complet du navigateur
     * @return {Promise<void>}
     */
    waitRequestAnimationFrame(): Promise<void> {
        return new Promise((resolve) => {
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        resolve()
                    })
                })
            })
        })
    }

    /**
     * @description Exécute une action selon l'état
     * @param {any} plage - Plage
     * @param {any} rdv - Rendez-vous
     * @param {any} indexC - Index du créneau
     * @return {void}
     */
    executeAction(plage: any, rdv: any, indexC: any): void {
        this.$store.state.disponibilite.error_dispos = null
        // On vérifie que le RDV n'est pas déjà lié en planification à des séances.
        // Si le rdv à déjà des séances, on ne peut pas le supprimer
        if (rdv.seances_count && rdv.seances_count !== 0 && this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW) && !this.$store.state.sessionuser.sessionUser.ajustements_validated_at) {
            this.$store.state.disponibilite.error_dispos = 'Le créneau est déjà utilisé en planification, vous ne pouvez pas le supprimer ou le désactiver.'
        } else {
            // Le rdv peut être modifié
            // Si la plage n'est pas grisée
            if (!this.isDisabled(plage.jours.j, rdv).disabled) {
            // Si les disponibilités sont validées, mais pas les ajustements
                if (this.$store.state.sessionuser.sessionUser && this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && !this.$store.state.sessionuser.sessionUser.ajustements_validated_at) {
                    this.activateDeactivate(plage.jours.j, plage.index, indexC)
                } else if (this.$store.state.sessionuser.sessionUser && !this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && !this.$store.state.sessionuser.sessionUser.ajustements_validated_at) {
                // Si le créneau englobe plusieurs possibilités de créneaux pour former une heure
                    if (plage.jours.rdvs.length > 1) {
                        this.checkMultiRDV(plage.jours.j, plage.index, indexC)
                    } else {
                        this.addUpdateDestroyCreneau(plage.jours.j, plage.index, indexC)
                    }
                }
            }
        }
    }

    /**
     * @description Vérifie si on peut enregistrer plusieurs créneaux
     * @param {any} jour - Jour
     * @param {number} indexP - Index de la plage
     * @param {number} index_rdv - Index du rendez-vous
     * @return {void}
     */
    checkMultiRDV(jour: any, indexP: any, index_rdv: number): void {
        this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
        const rdvs_select = this.creneaux[jour]['_' + indexP]
        let nb_creneaux_save_journee = 0
        let nb_creneaux_save_demi_journee = 0

        if (this.$store.state.disponibilite.compteurs_series_journees[this.selected_serie_id].journees[jour]) {
            nb_creneaux_save_journee = this.$store.state.disponibilite.compteurs_series_journees[this.selected_serie_id].journees[jour].filter((j: any) => j.jour === jour).length
        }

        let deja_save = false

        for (const r in rdvs_select) {
            if (rdvs_select[r].id !== 0) {
                deja_save = true
            }
        }

        // Applique les règles selon l'état
        if (this.applique_regle) {
            // Check le max créneaux pour la journée
            if (nb_creneaux_save_journee !== 0 && (nb_creneaux_save_journee + rdvs_select.length) > this.epreuve_correction.nb_cand_jour) {
            // Tous les créneaux ne pourront pas être enregistré, on n'enregistre que celui sélectionné
                deja_save = true
            }

            // Check le max pour la demi-journée
            if (this.creneaux[jour]['_' + indexP][index_rdv].h_debut.isBefore(this.$store.state.disponibilite.lock_creneau[jour].heure_demi_journee_moment)) {
                nb_creneaux_save_demi_journee = this.$store.state.disponibilite.lock_creneau[jour].nb_creneaux_matin
            } else {
                nb_creneaux_save_demi_journee = this.$store.state.disponibilite.lock_creneau[jour].nb_creneaux_aprem
            }

            if (nb_creneaux_save_demi_journee !== 0 && (nb_creneaux_save_demi_journee + rdvs_select.length) > this.epreuve_correction.nb_cand_demi_jour) {
            // Tous les créneaux ne pourront pas être enregistré, on n'enregistre que celui sélectionné
                deja_save = true
            }
        }

        if (!deja_save) {
            // On enregistre tous les créneaux en une seule fois, c'est la première sélection
            const tab_creneaux: any = []
            for (const r in rdvs_select) {
                // this.addUpdateDestroyCreneau(ligne_rdv, index_jour, Number(r))
                const rdv_select = this.creneaux[jour]['_' + indexP][Number(r)]
                // Création du payload
                const creneau = {
                    user_id: this.$store.getters['examinateur/examinateur_select'].id,
                    ensemble_id: this.ensemble_id, // this.$store.getters['examinateur/examinateur_select'].ensemble.id,
                    jour: jour,
                    h_debut: rdv_select.h_debut,
                    h_fin: rdv_select.h_fin,
                    etat: this.selected_priority_id,
                    actif: EtatCreneau.CRENEAU_ACTIF
                }

                tab_creneaux.push(creneau)
            }
            this.saveCreneaux(tab_creneaux)
        } else {
            // Sauvegarde / MAJ du creneau cliqué uniquement
            this.addUpdateDestroyCreneau(jour, indexP, index_rdv)
        }
        this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
    }

    /**
     * @description Enregistre les créneaux en mode multiple
     * @param {any} tab_creneaux - Tableau des créneaux
     * @return {void}
     */
    saveCreneaux(tab_creneaux: any): void {
        this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
        this.lockCreneau = true
        this.$store.dispatch('disponibilite/saveCreneau', { payload: { 'creneaux': tab_creneaux } })
            .then(() => {
                this.creneaux = this.$store.getters['disponibilite/get_creneaux'](this.plages)
                this.lockCreneau = false
                if (this.applique_regle) {
                    // Applique les règles à respecter pour la sélection des creneaux
                    this.$store.getters['disponibilite/check_regles_creneaux'](this.creneaux, this.epreuve_correction)
                }
                this.checkCreneauxDisabled()

                // Maj de l'interface
                tab_creneaux.forEach((tab_creneau: any) => {
                    this.$store.state.disponibilite.creneaux_examinateur.push(tab_creneau)
                })
                this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
            })
            .catch((error) => {
                this.lockCreneau = false
                console.log('ko:' + error)
            })
    }

    /**
     * @description Ajoute, met à jour ou supprime un créneau
     * @param {any} jour - Jour
     * @param {number} indexP - Index de la plage
     * @param {number} index_rdv - Index du rendez-vous
     * @return {void}
     */
    addUpdateDestroyCreneau(jour: any, indexP: any, index_rdv: number): void {
        this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
        this.$store.state.disponibilite.error_dispos = null
        const rdv_select = this.creneaux[jour]['_' + indexP][index_rdv]
        this.lockCreneau = true

        // CAS 1 : RDV ACTIF ET PRIORITE == PRIORITE SELECTIONNEE --> RETRAIT DU CRENEAU
        if (rdv_select.id && rdv_select.etat === this.selected_priority_id) {
            // Retrait du créneau
            this.$store.dispatch('disponibilite/deleteCreneau', { creneau_id: rdv_select.id })
                .then(() => {
                    this.$store.dispatch('disponibilite/getCreneaux', { user_id: this.$store.getters['examinateur/examinateur_select_infos'].id })
                        .then(() => {
                            this.creneaux = this.$store.getters['disponibilite/get_creneaux'](this.plages)

                            if (this.applique_regle) {
                                // Applique les règles à respecter pour la sélection des creneaux
                                this.$store.getters['disponibilite/check_regles_creneaux'](this.creneaux, this.epreuve_correction)
                            }

                            this.lockCreneau = false
                            // this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
                            this.checkCreneauxDisabled()
                            this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
                        })
                        .catch((error) => {
                            this.lockCreneau = false
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        }
        // CAS 2 : RDV ACTIF ET PRIORITE !== PRIORITE SELECTIONNEE --> MODIFICATION DU CRENEAU AVEC LA PRIORITE SELECTIONNEE
        else if (rdv_select.id && rdv_select.etat !== this.selected_priority_id) {
            const creneau = {
                user_id: this.$store.getters['examinateur/examinateur_select'].id,
                ensemble_id: this.ensemble_id, // this.$store.getters['examinateur/examinateur_select'].ensemble.id,
                jour: jour,
                h_debut: rdv_select.h_debut,
                h_fin: rdv_select.h_fin,
                etat: this.selected_priority_id,
                actif: rdv_select.actif
            }

            this.$store.dispatch('disponibilite/updateCreneau', { creneau_id: rdv_select.id, payload: creneau })
                .then(() => {
                    this.creneaux = this.$store.getters['disponibilite/get_creneaux'](this.plages)
                    if (this.applique_regle) {
                        // Applique les règles à respecter pour la sélection des creneaux
                        this.$store.getters['disponibilite/check_regles_creneaux'](this.creneaux, this.epreuve_correction)
                    }
                    this.lockCreneau = false
                    // this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
                    this.checkCreneauxDisabled()
                    this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        } else {
            // CAS 3 : CRENEAU TOUT NEUF --> AJOUT DU CRENEAU SUR LA PRIORITE SELECTIONNEE
            // Création du payload
            const creneau = {
                user_id: this.$store.getters['examinateur/examinateur_select'].id,
                ensemble_id: this.ensemble_id, // this.$store.getters['examinateur/examinateur_select'].ensemble.id,
                jour: jour,
                h_debut: rdv_select.h_debut,
                h_fin: rdv_select.h_fin,
                etat: this.selected_priority_id,
                actif: EtatCreneau.CRENEAU_ACTIF
            }

            this.$store.dispatch('disponibilite/saveCreneau', { payload: { 'creneaux': [creneau] } })
                .then(() => {
                    this.creneaux = this.$store.getters['disponibilite/get_creneaux'](this.plages)
                    this.lockCreneau = false
                    // this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
                    if (this.applique_regle) {
                        // Applique les règles à respecter pour la sélection des creneaux
                        this.$store.getters['disponibilite/check_regles_creneaux'](this.creneaux, this.epreuve_correction)
                    }
                    this.checkCreneauxDisabled()
                    this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * @description Active ou désactive un créneau
     * @param {any} jour - Jour
     * @param {number} indexP - Index de la plage
     * @param {number} index_rdv - Index du rendez-vous
     * @return {void}
     */
    activateDeactivate(jour: any, indexP: number, index_rdv: number): void {
        const rdv_select = this.creneaux[jour]['_' + indexP][index_rdv]
        if (rdv_select.id) {
            this.$store.state.disponibilite.error_dispos = null
            const rdv_select = this.creneaux[jour]['_' + indexP][index_rdv]
            const actif = (rdv_select.actif === EtatCreneau.CRENEAU_INACTIF ? EtatCreneau.CRENEAU_ACTIF : EtatCreneau.CRENEAU_INACTIF)

            const creneau = {
                user_id: this.$store.getters['examinateur/examinateur_select'].id,
                ensemble_id: this.ensemble_id,
                jour: jour,
                h_debut: rdv_select.h_debut.clone(),
                h_fin: rdv_select.h_fin.clone(),
                etat: rdv_select.etat,
                actif: actif
            }

            this.$store.dispatch('disponibilite/updateCreneau', { creneau_id: rdv_select.id, payload: creneau })
                .then(() => {
                    this.creneaux = this.$store.getters['disponibilite/get_creneaux'](this.plages)
                    this.lockCreneau = false
                    this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.ensemble_id)
                })
                .catch((error) => {
                    this.lockCreneau = false
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * @description Vérifie si le créneau est désactivé
     * @return {void}
     */
    checkCreneauxDisabled(): void {
        for (const i in this.rdvs) {
            for (const j in this.rdvs[i]) {
                for (const k in this.rdvs[i][j].jours.rdvs) {
                    const rdv = this.rdvs[i][j].jours.rdvs[k]
                    rdv.disabled = this.isDisabled(this.rdvs[i][j].jours.j, this.creneaux[this.rdvs[i][j].jours.j]['_' + this.rdvs[i][j].index][k])
                }
            }
        }
    }

    /**
     * @description Vérifie si le créneau est indisponible
     * @param {any} rdv - Créneau
     * @return {any} - Retourne un objet avec l'indisponibilité et l'ensemble concerné
     */
    checkCreneauIndisponible(rdv: any): any {
        if (this.$store.state.disponibilite.creneaux_examinateur && this.$store.state.disponibilite.creneaux_examinateur.length > 0) {
            const ce = this.$store.state.disponibilite.creneaux_examinateur
            const ensembles = this.$store.getters['examinateur/examinateur_select'].ensembles
            const ensembles_length = ensembles.length
            const rdv_debut = formatStringServeurToDate(rdv.h_debut)
            const rdv_fin = formatStringServeurToDate(rdv.h_fin)
            const ensemble_id = parseInt(this.ensemble_id)

            const isIndispo = ce.some((creneau: any) => {
                const ce_ensemble_id = parseInt(creneau.ensemble_id)
                const ce_h_debut = formatStringServeurToDate(creneau.h_debut)
                const ce_h_fin = formatStringServeurToDate(creneau.h_fin)

                if (ce_ensemble_id !== ensemble_id &&
                    (rdv_debut?.isSame(ce_h_debut) ||
                        rdv_debut === ce_h_debut ||
                        rdv_debut?.add(this.epreuve_correction.duree_preparation, 'minutes')?.isBetween(ce_h_debut, ce_h_fin) ||
                        rdv_fin?.isBetween(ce_h_debut?.add(this.epreuve_correction.duree_preparation, 'minutes'), ce_h_fin))) {
                    for (let j = 0; j < ensembles_length; j++) {
                        if (parseInt(ensembles[j].id) === ce_ensemble_id) {
                            return { indispo : true, ensemble: ensembles[j] || null }
                        }
                    }
                }

                return false
            })

            if (isIndispo) {
                return isIndispo
            }
        }

        return { indispo : false, ensemble: null }
    }

    /**
     * @description Retourne une classe adaptée pour l'affichage de la vignette
     * @param {any} rdv - Créneau
     * @return {string} - Retourne une classe adaptée pour l'affichage de la vignette
     */
    getClassEtatVignette(rdv: any): string {
        let return_classe = ''
        if (rdv.indispo) {
            return 'vignette_indisponible'
        }
        if (rdv.actif === EtatCreneau.CRENEAU_ACTIF && parseInt(rdv.ensemble_id) === parseInt(this.ensemble_id)) {
            if (rdv.etat === EtatDispo.ETAT_PRIORITAIRE) {
                return_classe = 'vignette_prioritaire'

                if ((!this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at) || (!this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE))) {
                // Check la priorité sélectionnée
                    if (this.selected_priority_id !== rdv.etat && this.$store.state.sessionuser.sessionUser && !this.$store.state.sessionuser.sessionUser.disponibilites_validated_at) {
                        return_classe += ' btn_priorite_no_select_info'
                    }
                }
            } else if (rdv.etat === EtatDispo.ETAT_NON_PRIORITAIRE) {
                return_classe = 'vignette_non_prioritaire'

                if ((!this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at) || (!this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE))) {
                // Check la priorité sélectionnée
                    if (this.selected_priority_id !== rdv.etat && this.$store.state.sessionuser.sessionUser && !this.$store.state.sessionuser.sessionUser.disponibilites_validated_at) {
                        return_classe += ' btn_priorite_no_select_purple'
                    }
                }
            }
        } else if (rdv.actif === EtatCreneau.CRENEAU_INACTIF && parseInt(rdv.ensemble_id) === parseInt(this.ensemble_id)) {
            if (rdv.etat === EtatDispo.ETAT_PRIORITAIRE) {
                return_classe = 'vignette_prioritaire'
            } else if (rdv.etat === EtatDispo.ETAT_NON_PRIORITAIRE) {
                return_classe = 'vignette_non_prioritaire'
            }

            if (!this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at || (!this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE))) {
                // Check la priorité sélectionnée
                if (this.selected_priority_id !== rdv.etat && this.$store.state.sessionuser.sessionUser && !this.$store.state.sessionuser.sessionUser.disponibilites_validated_at) {
                    if (rdv.etat === EtatDispo.ETAT_PRIORITAIRE) {
                        return_classe += ' btn_priorite_no_select_info'
                    } else if (rdv.etat === EtatDispo.ETAT_NON_PRIORITAIRE) {
                        return_classe += ' btn_priorite_no_select_purple'
                    }
                }
            }

            // Etat validé -> on affiche les inactifs au responsable
            if (this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE)) {
                if (rdv.etat === EtatDispo.ETAT_PRIORITAIRE) {
                    return_classe = 'vignette_inactive_prioritaire'
                } else if (rdv.etat === EtatDispo.ETAT_NON_PRIORITAIRE) {
                    return_classe = 'vignette_inactive_non_prioritaire'
                }
            }
        } else if ((rdv.actif === EtatCreneau.CRENEAU_ACTIF || rdv.actif === EtatCreneau.CRENEAU_INACTIF) && (parseInt(rdv.ensemble_id) !== parseInt(this.ensemble_id))) {
            return_classe = 'vignette_indisponible'
        } else if (rdv.actif === EtatCreneau.CRENEAU_INDISPONIBLE) {
            return_classe = 'vignette_indisponible'
        }

        return return_classe
    }

    /**
     * @description Retourne une classe adaptée pour l'affichage du hover selon la priorité sélectionnée
     * @return {string} - Retourne une classe adaptée pour l'affichage du hover
     */
    getClassHover(): string {
        let return_classe = ''
        // Hover sur les creneaux
        if (this.selected_priority_id === EtatDispo.ETAT_PRIORITAIRE) {
            return_classe += 'vignette_rdv_selected_priority'
        } else if (this.selected_priority_id === EtatDispo.ETAT_NON_PRIORITAIRE) {
            return_classe += 'vignette_rdv_selected_no_priority'
        }
        return return_classe
    }

    /**
     * @description Retourne une classe adaptée pour l'affichage du tooltip selon la place
     * @param {any} index - Index de la ligne
     * @param {any} total_index - Total des lignes
     * @return {string} - Retourne une classe adaptée pour l'affichage du tooltip
     */
    getClassTooltip(index: any, total_index: any): string {
        // Affichage intérieur droit si index entre 0 et 3
        if (Number(index) >= 0 && Number(index) <= 3) {
            return 'tooltiptext tooltiptext_ID'
        }

        // Affichage intérieur gauche si index entre 4 et le total des lignes
        if (Number(index) >= 4 && Number(index) <= Number(total_index)) {
            return 'tooltiptext tooltiptext_IG'
        }
        return 'tooltiptext'
    }

    /**
     * @description Vérifie si le créneau doit être désactivé ou non
     * @param {string} jour - Jour de la semaine
     * @param {any} rdv - Créneau à vérifier
     * @return {any} - Retourne un objet avec un boolean pour indiquer si le créneau doit être disabled ou non et un message
     */
    isDisabled(jour: string, rdv: any): any {
        // Check le readOnly pour les épreuves
        const checkedCreneauIndispo = this.checkCreneauIndisponible(rdv)
        if (checkedCreneauIndispo.indispo) {
            return { disabled: true, title: 'Créneaux réservés à l\'équipe ' +  checkedCreneauIndispo.ensemble.name }
        }

        if (this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE) &&
            this.$store.state.sessionuser.sessionUser.ajustements_validated_at &&
            this.epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP) {
            this.readOnly = true
        } else if (this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN) &&
            this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at &&
            this.epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP) {
            this.readOnly = true
        } else {
            this.readOnly = !this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE) && !this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN)
        }

        let message = null
        // Applique les règles selon l'état de la saisie des dispos et le profil de l'utilisateur connecté
        if (this.applique_regle) {
            const h_debut_test = formatDayHourZDateFromMoment(rdv.h_debut)
            const lock_store = (this.$store.state.disponibilite.lock_creneau[jour] && this.$store.state.disponibilite.lock_creneau[jour].plages) ? this.$store.state.disponibilite.lock_creneau[jour].plages.find((cr: any) => cr.h_debut === h_debut_test) : null
            const lock_index = (this.$store.state.disponibilite.lock_creneau[jour] && this.$store.state.disponibilite.lock_creneau[jour].plages) ? this.$store.state.disponibilite.lock_creneau[jour].plages.findIndex((cr: any) => cr.h_debut === h_debut_test) : null

            if (this.$store.state.disponibilite.messages_regles_journees[jour] && this.$store.state.disponibilite.messages_regles_journees[jour].message_creneaux[lock_index] !== '') {
                message = this.$store.state.disponibilite.messages_regles_journees[jour].message_creneaux[lock_index]
            }
            if (this.readOnlyGroup) {
                if (this.$store.getters['examinateur/examinateur_select_infos'].id !== this.$store.state.auth.user.id) {
                    message = 'Étant dans un groupe d\'examinateurs, les disponibilités ne peuvent pas être éditées.'
                } else {
                    message = 'Vous êtes membre du groupe. Les disponibilités seront saisies par le responsable du groupe.'
                }
            }

            if (!this.readOnly && !this.readOnlyGroup) {
                if ((this.lockCreneau || (lock_store && lock_store.disabled))) {
                    return { disabled: true, title: message }
                } else {
                    return { disabled: false, title: message }
                }
            } else {
                return { disabled: true, title: message }
            }
        } else {
            if (!this.readOnly && !this.readOnlyGroup) {
                return { disabled: false, title: message }
            } else {
                return { disabled: true, title: message }
            }
        }
    }

    /**
     * @description Affiche une marge si on a une pause définie dans la journée ou affiche une marge pour dissocier les demi-journées (13h)
     * @param {any} h_debut - Heure de début du créneau
     * @param {any} jour - Jour de la semaine
     * @param {string} context - Contexte d'affichage (rdv ou entete)
     * @param {any} indexL - Index de la ligne
     * @param {number} nb_rdvs - Nombre de RDV sur la ligne
     * @return {string} - Retourne une classe pour la marge
     */
    getMargePause(h_debut: any, jour: any, context: string, indexL: any, nb_rdvs: number): string {
        // Compensation pour les multi RDV sur une heure
        if (nb_rdvs >= 3) {
            indexL = indexL - 1
        }

        // Check si on a une pause définie
        if (this.$store.state.disponibilite.pause_count !== 0 && this.$store.state.disponibilite.pause_count === indexL) {
            if (context === 'rdv') {
                return 'mt-3'
            } else if (context === 'entete') {
                return 'pad_top_15'
            }
        } else {
            // Check si l'heure de début du créneau est similaire à l'heure de demi-journée définie par défaut
            const plage_index_h =  h_debut.format('HH')
            const  plage_index_test = this.$store.state.disponibilite.lock_creneau[jour].heure_demi_journee_moment.format('HH')
            if (plage_index_h === plage_index_test) {
                if (context === 'rdv') {
                    return 'mt-3'
                } else if (context === 'entete') {
                    return 'pad_top_15'
                }
            }
        }

        return ''
    }

    /**
     * @description Retourne la ventilation par demi-journée des compteurs
     * @param {any} journees - Journées de la série
     * @param {string} jour - Jour de la semaine
     * @param {string} periode - Période de la journée
     * @return {number} - Retourne le nombre de créneaux pour la demi-journée
     */
    getCountDemiJournee(journees: any, jour: any,  periode: string): number {
        const lock_creneau = this.$store.state.disponibilite.lock_creneau

        if (periode === 'AM' && journees) {
            return journees.filter((j: any) => j.jour === jour && j.creneau_h_debut_moment && lock_creneau[jour] && j.creneau_h_debut_moment.isBefore(lock_creneau[jour].heure_demi_journee_moment) && j.etat === EtatDispo.ETAT_PRIORITAIRE && parseInt(j.creneau.ensemble_id) === parseInt(this.ensemble_id)).length
        } else if (periode === 'PM' && journees) {
            return journees.filter((j: any) => j.jour === jour && j.creneau_h_debut_moment && lock_creneau[jour] &&  j.creneau_h_debut_moment.isSameOrAfter(lock_creneau[jour].heure_demi_journee_moment) && j.etat === EtatDispo.ETAT_PRIORITAIRE && parseInt(j.creneau.ensemble_id) === parseInt(this.ensemble_id)).length //  && j.creneau.esemble_id === this.ensemble_id
        }

        return 0
    }

    /**
     * @description Vérifie si l'utilisateur connecté est le créateur du créneau
     * @param {any} creneau - Créneau à vérifier
     * @return {string} - Retourne une étoile si le créneau a été créé par un autre examinateur
     */
    is_creator(creneau: any): string {
        if (creneau.creator_id !== undefined) {
            if ((this.$store.getters['examinateur/examinateur_select'].id !== creneau.creator_id) && parseInt(creneau.ensemble_id) === parseInt(this.ensemble_id)) {
                this.$emit('create_by_other', true)
                return '<span class="fw-bold indication_resp">*</span>'
            }
        }
        return ''
    }

    load () {
        if (this.selected_serie_id && this.ensemble_id && this.epreuve_correction) {
            this.setGabaritSemainier()
        }
    }

    /**
     * @description Montage du composant
     * @return {void}
     */
    mounted(): void {
        this.load()
        
    }
}
